import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { Container, Row, Col } from "react-bootstrap";

import {
  Title,
  Button,
  Box,
  Text,
  Input,
  Checkbox,
} from "../../components/Core";

import { device } from "../../utils";

import imgShape from "../../assets/image/svg/contact-shape.svg";

const BoxStyled = styled(Box)`
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 49%;
    content: "";
    width: 100%;
    background: ${({ theme }) => theme.colors.dark};
    z-index: 1;
  }
`;

const Shape = styled(Box)`
  position: absolute;
  width: 150%;
  bottom: 48%;
  left: -5%;

  @media ${device.lg} {
    bottom: 47%;
  }
`;

const FormStyled = styled.form`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  box-shadow: ${({ theme }) => `0 20px 61px ${theme.colors.shadow}`};
  border-radius: 10px;
  background-color: ${({ theme }) => `${theme.colors.lightShade}`};
  width: 100%;
`;

const Contact = () => {
  const [submitted, setSubmitted] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const GATEWAY_URL = "/rest/contact";

  const onSubmit = async (e) => {
    e.preventDefault();
    const [first, ...rest] = name.split(" ");
    const last = rest.join(" ");
    console.log(first, last); // good, luck_buddy

    const result = await addToMailchimp(email, {
      FNAME: first,
      LNAME: last,
      MESSAGE: "Where page",
    });

    console.log(result);

    if (result.result === "success") setSubmitted("Thanks!");
    else setSubmitted(result.msg);
  };

  const showThankYou = (
    <Text
      color="light"
      dangerouslySetInnerHTML={{
        __html: `<Text color="light">${submitted}</Text>`,
      }}
    />
  );

  const showForm = (
    <FormStyled method="post">
      <div className="mb-5 text-center">
        <Title className="mb-2">Let’s stay in touch</Title>
      </div>

      <Box mb={3}>
        <Input
          type="text"
          placeholder="Your name"
          name="name"
          id="name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </Box>
      <Box mb={3}>
        <Input
          type="email"
          placeholder="Your email"
          name="email"
          id="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </Box>

      <Button width="100%" type="submit" borderRadius={10} onClick={onSubmit}>
        Send
      </Button>
    </FormStyled>
  );

  return (
    <>
      <BoxStyled bg="secondary" pt={16} pb={6} className="position-relative">
        <Shape>
          <img src={imgShape} alt="" className="w-100 img-fluid" />
        </Shape>
        <Container
          css={`
            position: relative;
            z-index: 10;
          `}
        >
          <Row className="justify-content-center">
            <Col
              lg="9"
              xl="8"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              {submitted ? showThankYou : showForm}
            </Col>
          </Row>
        </Container>
      </BoxStyled>
    </>
  );
};

export default Contact;
