import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

const SectionStyled = styled(Section)``;

const Content = () => {
  return (
    <>
      {/* <!-- Content section --> */}
      <SectionStyled bg="secondary">
        <Container>
          <Row className="justify-content-center">
            <Col lg="10">
              <div className="text-center">
                <Title color="primary">
                  We believe that collectively we can shift the floral industry
                  to prioritize sourcing from local growers.
                </Title>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="justify-content-center align-content-center">
              <Col lg={{ span: 10, offset: 1 }}>
                <Text>
                  It will not happen overnight, and it will take the best and
                  brightest of our ideas. Not just us, Petaler, but you. When we
                  make it easier for farmers to run their businesses
                  efficiently, and communicate with their customers effectively,
                  local flowers and local growers win. All ideas welcome.
                </Text>
              </Col>
            </div>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content;
