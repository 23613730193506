import React from "react";
import Hero from "../sections/where/Hero";
import Content from "../sections/where/Content";
import CaseStudies from "../sections/where/CaseStudies";
import Contact from "../sections/where/Contact";

import PageWrapper from "../components/PageWrapper";

const LandingPage7 = () => {
  return (
    <>
      <PageWrapper footerDark headerDark>
        <Hero />
        <Content />
        <CaseStudies />
        <Contact />
      </PageWrapper>
    </>
  );
};
export default LandingPage7;
