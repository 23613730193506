import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Box, Text, Button } from "../../components/Core";
import { device } from "../../utils";

const CaseCardStyled = styled(Box)`
  width: 100%;
  transition: 0.4s;
  border-radius: 8px;

  .img-container {
    position: relative;
    z-index: 1;
    img {
      border-radius: 8px 8px 0 0;
      max-width: 100%;
    }
  }

  &:hover {
    transform: translateY(-20px);
  }
  &:hover i {
    transform: translateX(10px);
    opacity: 1;
  }
`;

const BtnContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin-top: 20px;
  opacity: 0;
  transition: 0.4s opacity, 0.4s margin-top;
  height: 40px;
  font-size: 16px;
  text-align: center;
  ${CaseCardStyled}:hover & {
    opacity: 1;
    margin-top: 0px;
  }
  button {
    font-size: 16px;
    padding: 0.5rem 1.25rem;
    @media ${device.sm} {
      font-size: 18px;
      padding: 0.75rem 1.5rem;
    }
    @media ${device.lg} {
      padding: 0.85rem 1.75rem;
    }
  }
`;

const TextContent = styled(Box)`
  position: relative;
  height: 330px;
  z-index: 1;
  transition: 0.4s;
  padding-top: 23px;
  border-radius: 8px;
  padding-bottom: 21px;
  padding-left: 20px;
  padding-right: 20px;

  @media ${device.sm} {
    padding-top: 43px;
    padding-bottom: 41px;
    padding-left: 38px;
    padding-right: 38px;
  }
`;

const PreTitle = styled(Text)`
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-bottom: 22px;
`;

const TitleStyled = styled(Title)`
  letter-spacing: -1.06px;
  margin-bottom: 22px;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  @media ${device.sm} {
    font-size: 34px;
    font-weight: 700;
  }
`;

const CaseCard = ({
  isDark = true,
  bg = "secondary",
  meta = "",
  title = "",
  children = "",
}) => {
  return (
    <CaseCardStyled>
      <TextContent bg={bg}>
        {false && (
          <BtnContainer>
            <Button>View Case Study</Button>
          </BtnContainer>
        )}
        <PreTitle color={isDark ? "lightShade" : "darkShade"}>{meta}</PreTitle>
        <TitleStyled color={isDark ? "light" : "dark"}>{title}</TitleStyled>
        <Text color={isDark ? "lightShade" : "darkShade"}>{children}</Text>
      </TextContent>
    </CaseCardStyled>
  );
};

const CaseStudies = () => {
  return (
    <>
      {/* <!-- Content section --> */}
      <Box bg="secondary">
        <Container className="pb-5">
          <Title variant="small-section" mb={4}>
            Here's what is in the works
          </Title>
          <Row className="justify-content-center">
            <Col
              lg="6"
              md="9"
              className="mb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <CaseCard
                bg="light"
                isDark={false}
                meta={`Bring our mobile platform to the browser`}
                title="Petaler Web Platform"
              >
                Petaler accessible on your computer as well as your phone. This
                is coming soon!
              </CaseCard>
            </Col>
            <Col
              lg="6"
              md="9"
              className="mb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <CaseCard
                bg="light"
                isDark={false}
                meta={`A point of sale system that is built for flowers`}
                title="Petaler Direct"
              >
                Juggle less and get all encompassing reporting by funneling
                sales through 1 platform.
              </CaseCard>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col
              lg="6"
              md="9"
              className="mb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <CaseCard
                bg="light"
                isDark={false}
                meta={`An effortless online store for your existing website`}
                title="Petaler Online Store"
              >
                Now that you can update your wholesale availability lists in a
                snap, lets bring the same ease to your average customer.
              </CaseCard>
            </Col>
            <Col
              lg="6"
              md="9"
              className="mb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <CaseCard
                bg="light"
                isDark={false}
                meta={`Detailed reporting on how your business is doing`}
                title="Petaler Reports"
              >
                Get insights on what is available, in-demand, most profitable,
                when, and what sales channels work best for your farm.
              </CaseCard>
            </Col>
          </Row>
        </Container>
      </Box>
    </>
  );
};

export default CaseStudies;
