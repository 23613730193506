import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box } from "../../components/Core";
import { device } from "../../utils";
import flowers from "../../assets/image/jpeg/bouq.jpg";

const SectionStyled = styled(Section)`
  background: url(${flowers}) no-repeat;
  background-size: cover;
  background-position: bottom left;

  @media ${device.lg} {
    background-position: center;
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled>
        <div className="pt-5"></div>
        <Container
          className="position-relative"
          css={`
            z-index: 10;
          `}
        >
          <Row>
            <Col md="7">
              <Box py={4}>
                <Title
                  my={2}
                  color="primary"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-once="true"
                  data-aos-delay="1000"
                >
                  We have big ideas <br className="d-none d-lg-block" />
                  for local flowers
                </Title>
                {false && (
                  <Box
                    pt="12px"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-once="true"
                    data-aos-delay="1300"
                  >
                    <Button>Share yours</Button>
                  </Box>
                )}
              </Box>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
